import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadComponent } from '../head/head.component';
import { HeaderComponent } from '../layout/header/header.component';
import { FooterComponent } from '../layout/footer/footer.component';
import { HomeComponent } from '../home/home.component';
import { RouterOutlet } from '@angular/router';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'main-component',
  standalone: true,
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  imports: [
    CommonModule,
    HeadComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    RouterOutlet,
  ],
})
export class MainComponent {
  url = 'pump';
  constructor(location: Location, router: Router) {
    router.events.subscribe((val) => {
      this.url = location.path();
    });
  }

  showLayout() {
    return !['/square', '/pump'].find((base) => this.url.includes(base));
  }
}
