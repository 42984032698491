import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import {
  ReqCarAtPump,
  ReqPumpLog,
  ResCarAtPump,
} from '../pump-main/dto/pump.dto';
import { Observable } from 'rxjs';

@Injectable()
export class PumpSocket extends Socket {
  constructor() {
    super({ url: environment.apiUrl, options: {} });
  }
}

@Injectable()
export class PumpService {
  constructor(private pumpSocket: PumpSocket) {}

  merchantId = 'noMerchantId';
  pump = 0;

  init(req: ReqCarAtPump): Observable<ResCarAtPump> {
    this.merchantId = req.merchantId;
    this.pump = req.pump;
    this.pumpSocket.on('connect', () => {
      this.pumpSocket.emit('ReqCarAtPump', req);
    });
    [
      'connect',
      'disconnect',
      'error',
      'ping',
      'reconnect',
      'reconnect_attempt',
      'reconnect_error',
      'reconnect_failed',
    ].forEach((eventName) => {
      this.pumpSocket.on(eventName, (...data: any[]) => {
        // console.log(new Date(), ' - pumpSocket event: ' + eventName, ...data);
      });
    });

    return this.pumpSocket.fromEvent('ResCarAtPump');
  }

  log(...params: any) {
    const log: ReqPumpLog = {
      merchantId: this.merchantId,
      pump: this.pump,
      logs: params,
    };
    this.pumpSocket.emit('ReqPumpLog', log);
  }
}
