import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadComponent } from '../../head/head.component';
import { FooterComponent } from '../../layout/footer/footer.component';
import { HeaderComponent } from '../../layout/header/header.component';

@Component({
  selector: 'app-refund',
  standalone: true,
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
  imports: [CommonModule, HeadComponent, FooterComponent, HeaderComponent],
})
export class RefundComponent {}
