import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../../layout/footer/footer.component';
import { HeadComponent } from '../../head/head.component';
import { HeaderComponent } from '../../layout/header/header.component';

@Component({
  selector: 'app-privacy',
  standalone: true,
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  imports: [CommonModule, FooterComponent, HeadComponent, HeaderComponent],
})
export class PrivacyComponent {}
