export const defaultEnvironment = {
  production: false,
  apiUrl: 'http://localhost:3000',
  staxWebToken: 'sdad-24aba27378cf',
  paymentProvider: 'square',
  square: {
    appId: 'sandbox-sq0idb-pCyUGpTq2UTf5kfamc0LqA',
    locationId: 'L6B7JVF7HNP8F',
    scriptUrl: 'https://sandbox.web.squarecdn.com/v1/square.js',
  },
};
