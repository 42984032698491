import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { PrivacyComponent } from './app/web/pages/privacy/privacy.component';
import { RefundComponent } from './app/web/pages/refund/refund.component';
import { TosComponent } from './app/web/pages/tos/tos.component';
import { SquareSignupComponent } from './app/square-signup/square-signup.component';
import { HomeComponent } from './app/web/home/home.component';
import { PumpMainComponent } from './app/pump/pump-main/pump-main.component';

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideEnvironmentNgxMask(),

    provideRouter([
      { path: '', component: HomeComponent },
      { path: 'tos', component: TosComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'refund', component: RefundComponent },
      { path: 'square', component: SquareSignupComponent },
      { path: 'pump', component: PumpMainComponent },
    ]),
  ],
});
