import { AfterViewInit, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
declare const Square: any;
declare const window: any;

@Component({
  selector: 'app-square-signup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './square-signup.component.html',
  styleUrls: ['./square-signup.component.scss'],
})
export class SquareSignupComponent implements AfterViewInit {
  squareCard: any;
  payments: any;
  config: any;
  baseUrl: any;
  cardButton: any;
  token: any;
  postalCode = '';

  constructor(private route: ActivatedRoute) {
    this.config = environment.square;
    this.baseUrl = environment.apiUrl;
  }
  async ngAfterViewInit(): Promise<void> {
    await this.initSquare();
    this.cardButton = document.getElementById('card-button');
    this.cardButton.add;
    this.cardButton.addEventListener('click', async (event: any) => {
      await this.handleStoreCardSubmission(event);
    });
  }
  async initSquare() {
    this.route.queryParams.subscribe(async (p) => {
      this.postalCode = p['postalCode'];
    });
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('type', 'text/javascript');
    scriptElement.setAttribute('src', this.config.scriptUrl);
    scriptElement.onload = async () => {
      try {
        this.payments = Square.payments(
          this.config.appId,
          this.config.locationId,
        );
        window.payments = this.payments;
        this.squareCard = await this.payments.card({
          postalCode: this.postalCode,
        });
        window.squareCard = this.squareCard;
        await this.squareCard.attach('#credit-card');
      } catch (err) {
        console.error(err);
      }
    };
    document.head.appendChild(scriptElement);
  }

  displayResults(status: string) {
    const statusContainer: any = document.getElementById(
      'payment-status-container',
    );
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }
    statusContainer.style.visibility = 'visible';
  }

  async handleStoreCardSubmission(event: any) {
    try {
      event.preventDefault();
      this.cardButton.disabled = true;
      this.token = await this.squareCard.tokenize();
      if (this.token.status === 'OK') {
        this.token = this.token.token;
      } else {
        console.log(
          `Tokenization errors: ${JSON.stringify(this.token.errors)}`,
        );
      }
      console.log(`TOKENIZED-CARD:${JSON.stringify(this.token)}`);
      // This is tightly coupled to the backend using page.on console, TOKENIZED-CARD:  is used to differentiate between different console.logs
      this.displayResults('SUCCESS');
    } catch (error) {
      this.cardButton.disabled = false;
      console.log(error);
      this.displayResults('FAILURE');
    }
  }
}
