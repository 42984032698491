<header>
  <div class="content">
    <a href="/" class="logo"> </a>

    <div class="links desktop">
      <nav>
        <ul>
          <li><a href="/#register">Save 3¢/gal join now</a></li>
          <li><a href="/#locations">Locations</a></li>
          <li><a href="/#contact-us">Contact us</a></li>
        </ul>
      </nav>
    </div>

    <div class="save mobile">
      <a href="/#register">Save 3¢/gal join now</a>
    </div>
    <div
      class="menu-button mobile"
      [ngClass]="{ 'menu-show': mobileMenuVisible }"
    >
      <mat-icon
        (click)="toggleMobileMenu()"
        aria-hidden="false"
        aria-label="menu"
        fontIcon="menu"
      ></mat-icon>
      <div class="overlay" (click)="toggleMobileMenu()"></div>
      <div class="menu">
        <mat-icon
          (click)="toggleMobileMenu()"
          aria-hidden="false"
          aria-label="menu close"
          fontIcon="close"
        ></mat-icon>
        <nav>
          <ul>
            <li>
              <a href="/#register" (click)="toggleMobileMenu()">
                Save 3¢/gal join now
              </a>
            </li>
            <li>
              <a href="/#locations" (click)="toggleMobileMenu()">Locations</a>
            </li>
            <li>
              <a href="/#contact-us" (click)="toggleMobileMenu()">Contact us</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</header>
