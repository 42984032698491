import { Component } from '@angular/core';

import { GoogleMapsModule } from '@angular/google-maps';
import { MainComponent } from './web/main/main.component';

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MainComponent, GoogleMapsModule],
})
export class AppComponent {}
