<div class="container" [formGroup]="form">
  <div class="welcome-text no-select" *ngIf="currentState === State().Welcome">
    <!-- <p>{{ version }}</p> -->
    <p class="zip-code" *ngIf="!this.emptyPlate()">
      Swipe payment card with the mag strip facing up to register it with
      plate<br />
      <strong>{{ prettyLicensePlate(plate) }}</strong>
    </p>
    <p class="zip-code" *ngIf="this.emptyPlate()">
      Swipe payment card with the mag strip facing up to start
    </p>
  </div>

  <div
    class="zip-code flex-row-parent"
    *ngIf="currentState === State().ZipCode"
  >
    <div class="flex-row-child">
      <p class="no-select">Enter your zip code</p>
      <mat-form-field style="width: 12em">
        <input
          id="zipCode"
          pattern="[0-9]*"
          inputmode="numeric"
          formControlName="zipCode"
          type="text"
          readonly
          inputmode="none"
          required
          matInput
          mask="00000"
        />
      </mat-form-field>
    </div>
    <div class="flex-row-child">
      <keypad
        [length]="5"
        (done)="advanceState()"
        (newValue)="updateValue($event)"
      ></keypad>
    </div>
  </div>

  <div
    class="phone-number flex-row-parent"
    *ngIf="currentState === State().Cvv"
  >
    <div class="flex-row-child">
      <p>Enter 3 digits security code on the back of your card</p>
      <mat-form-field style="width: 10em">
        <input
          id="cvv"
          formControlName="cvv"
          type="text"
          inputmode="none"
          required
          matInput
          mask="000"
        />
      </mat-form-field>
    </div>

    <div class="flex-row-child">
      <keypad
        [length]="3"
        (done)="advanceState()"
        (newValue)="updateValue($event)"
      ></keypad>
    </div>
  </div>

  <div
    class="phone-number flex-row-parent"
    *ngIf="currentState === State().PhoneNumber"
  >
    <div class="flex-row-child">
      <p>Enter your phone number to complete your registration</p>
      <mat-form-field>
        <input
          id="phoneNumber"
          formControlName="phoneNumber"
          type="text"
          inputmode="none"
          required
          matInput
          mask="(000) 000-0000"
        />
      </mat-form-field>
    </div>
    <div class="flex-row-child">
      <keypad
        [length]="10"
        (done)="advanceState()"
        (newValue)="updateValue($event)"
      ></keypad>
    </div>
  </div>

  <div class="fuel" *ngIf="currentState === State().Waiting">
    <p>One moment please.</p>
    <div class="progress-holder">
      <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
    </div>
  </div>

  <div class="fuel" *ngIf="currentState === State().Authorized">
    <p *ngIf="this.emptyPlate()">Please lift fuel nozzle and select grade.</p>

    <p *ngIf="!this.emptyPlate()">
      This pump has been authorized for the plate <br />
      <strong>{{ prettyLicensePlate(plate) }}</strong>
      <br />
      Please lift fuel nozzle and select grade.
    </p>
  </div>
</div>
