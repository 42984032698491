<section class="container">
  <h1>SIGN UP AND START SAVING!</h1>

  <div class="stepper">
    <div class="line"></div>
    <div class="steps">
      <div class="step" [ngClass]="{ active: currentStep === 1 }">
        <div class="num">1</div>
        <div class="label">Plate</div>
      </div>
      <div class="step" [ngClass]="{ active: currentStep === 2 }">
        <div class="num">2</div>
        <div class="label">Card</div>
      </div>
      <div class="step" [ngClass]="{ active: currentStep === 3 }">
        <div class="num">3</div>
        <div class="label">Done!</div>
      </div>
    </div>
  </div>

  <div
    *ngIf="currentStep === 1"
    class="form"
    [formGroup]="registrationFormStepOne"
  >
    <mat-form-field>
      <mat-label>Plate</mat-label>
      <input
        id="plate"
        formControlName="plate"
        required
        matInput
        placeholder="BLL PLT"
        mask="AAAA AAAA"
        minlength="4"
        maxlength="9"
        [validation]="false"
      />
      <mat-error> Invalid license plate. </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input
        formControlName="phone"
        autocomplete="tel-national"
        type="tel"
        required
        matInput
        placeholder="123-123-1234"
        mask="(000) 000-0000"
        (keydown)="nextStepInput($event)"
      />
      <mat-error>
        Invalid phone number. Please enter a valid US phone number format (e.g.,
        123-456-7890).
      </mat-error>
    </mat-form-field>

    <!-- <mat-form-field appearance="outline">
      <input formControlName="email" required matInput placeholder="email" />
      <mat-error
        >Invalid Email. Please enter a valid Email e.g. johndoe@gmail.com
        .</mat-error
      >
    </mat-form-field> -->

    <div class="inputs-row-buttons">
      <button mat-flat-button (click)="nextStep()">Next</button>
    </div>
  </div>

  <div
    *ngIf="currentStep === 2"
    class="form"
    [formGroup]="registrationFormStepTwo"
  >
    <!-- <app-stax
      [userBasicInfo]="{
        firstName: this.registrationFormStepTwo.value.firstName,
        lastName: this.registrationFormStepTwo.value.lastName,
        plate: [this.registrationFormStepOne.value.plate],
        phone: this.registrationFormStepOne.value.phoneNumber,
        email: this.registrationFormStepOne.value.email,
        addressLine1: this.registrationFormStepTwo.value.addressLine,
        city: this.registrationFormStepTwo.value.city,
        state: this.registrationFormStepTwo.value.state
      }"
    ></app-stax> -->

    <mat-form-field>
      <mat-label>Card Number</mat-label>
      <input
        formControlName="cardNumber"
        autocomplete="cc-number"
        required
        matInput
        placeholder="4111 1111 1111 1111"
        mask="0000 0000 0000 0000"
        type="tel"
      />
      <mat-error>
        Invalid Card Number. Please enter a valid Card Number of 16 digits.
      </mat-error>
    </mat-form-field>

    <div class="inputs-row">
      <mat-form-field>
        <mat-label>Exp. Date</mat-label>
        <input
          formControlName="cardExp"
          required
          matInput
          autocomplete="cc-exp"
          placeholder="MM / YY"
          mask="00/00"
          type="tel"
        />
        <mat-error>
          Invalid Expiry Date. Please enter the expiry Date written on your card
          e.g. "10/25".
        </mat-error>
      </mat-form-field>
      <div class="inputs-row-space"></div>
      <mat-form-field>
        <mat-label>Security Code</mat-label>
        <input
          formControlName="cardCvv"
          autocomplete="cc-csc"
          required
          matInput
          placeholder="123"
          mask="000"
          type="tel"
          (keydown)="nextStepInput($event)"
        />
        <mat-error>
          Invalid Card CVV. Please enter the last three digits that are written
          on the back of your card.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="inputs-row-buttons">
      <button mat-flat-button (click)="nextStep()">Next</button>
      <button mat-flat-button (click)="previousStep()">Previous</button>
    </div>
  </div>

  <div
    *ngIf="currentStep === 3"
    class="form"
    [formGroup]="registrationFormStepThree"
  >
    <div class="inputs-row">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input
          formControlName="firstName"
          autocomplete="given-name"
          required
          matInput
          placeholder="First Name"
          #firstNameInput
          (change)="
            firstNameInput.value =
              firstNameInput.value[0].toUpperCase() +
              firstNameInput.value.substring(1).toLowerCase()
          "
          mask="AAAAAAAAAAAAAAAAAAAA"
          [validation]="false"
        />
      </mat-form-field>
      <div class="inputs-row-space"></div>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input
          formControlName="lastName"
          autocomplete="family-name"
          required
          matInput
          placeholder="Last Name"
          #lastNameInput
          (change)="
            lastNameInput.value =
              lastNameInput.value[0].toUpperCase() +
              lastNameInput.value.substring(1).toLowerCase()
          "
          mask="AAAAAAAAAAAAAAAAAAAA"
          [validation]="false"
        />
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Address Line</mat-label>
      <input
        formControlName="addressLine1"
        required
        matInput
        autocomplete="address-line1"
        placeholder="Address Line"
      />
    </mat-form-field>

    <div class="inputs-row">
      <mat-form-field>
        <mat-label>City</mat-label>
        <input
          formControlName="city"
          required
          matInput
          placeholder="City"
          autocomplete="city"
        />
        <mat-error
          >Invalid city. Only one word with alphabetic characters is
          allowed.</mat-error
        >
      </mat-form-field>
      <div class="inputs-row-space"></div>
      <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select formControlName="state" autocomplete="state">
          <mat-option value="">Select a state</mat-option>
          <mat-option *ngFor="let state of statesArray" [value]="state">{{
            state
          }}</mat-option>
        </mat-select>
        <mat-error
          >Invalid state. Please enter a valid US state code. e.g. FL,
          MO</mat-error
        >
      </mat-form-field>
    </div>

    <div class="inputs-row-buttons">
      <button mat-flat-button (click)="submit()">Submit</button>
      <button mat-flat-button (click)="previousStep()">Previous</button>
    </div>
  </div>
</section>
