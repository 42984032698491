import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UserRegistrationComponent } from '../user-registration/user-registration.component';
import { MapComponent } from '../map/map.component';
import { ContactComponent } from './components/contact/contact.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UserRegistrationSquareComponent } from '../user-registration-square/user-registration-square.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    UserRegistrationComponent,
    MapComponent,
    ContactComponent,
    CommonModule,
    UserRegistrationSquareComponent,
  ],
})
export class HomeComponent implements OnInit {
  paymentProvider: string;

  constructor() {
    this.paymentProvider = environment.paymentProvider;
  }
  ngOnInit(): void {}
}
