<div class="text-page">
  <h1>Refund Policy</h1>
  <p>This Refund Policy describes how BillMyPlate.com process refunds.</p>
  <h2>In what cases a refund can be issued?</h2>
  <p>
    The only cases a refund can be issued is if a payment is proved to be
    collected in error. All payments collected by BillMyPlate.com happen when a
    vehicle that has the license plate number registered with the system is
    detected. During this a photo is taken of the vehicle car and license plate.
    All payments are final except those proven they have happened in error. If
    you believe a payment has been made in error, please contact us by using one
    of the methods below:
  </p>

  <ul>
    <li>Email: support@billmyplate.com</li>
    <li>Phone: 813 333 3395</li>
  </ul>
</div>
