import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import axios from 'axios';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { environment } from 'src/environments/environment';
import { PumpService, PumpSocket } from '../module/pump.service';
import { ResCarAtPump } from './dto/pump.dto';
import { AuthorizePumpVisitorDto } from './dto/authorize-pump-visitor.dto';
import { KeypadComponent } from '../keypad/keypad.component';

export enum State {
  Welcome = 0,
  ZipCode,
  Cvv,
  PhoneNumber,
  Waiting,
  Authorized,
}

interface validator {
  (): boolean;
}

const testCardData =
  '%B4839502591355742^GARAS/YOUSSEF ^2508201190100000000000943000000?;4111111111111111=25082011901094300000?';
const noPlate = 'noplat';

@Component({
  selector: 'app-pump-main',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatProgressSpinnerModule,
    KeypadComponent,
  ],
  providers: [PumpService, PumpSocket],
  templateUrl: './pump-main.component.html',
  styleUrls: ['./pump-main.component.scss'],
})
export class PumpMainComponent {
  form!: FormGroup;
  currentState = State.Welcome;
  cardData = '';
  plate: string = '';
  pump!: number;
  merchantId!: string;
  version = 1;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private pumpService: PumpService,
  ) {}
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      zipCode: ['', [Validators.minLength(5)]],
      cvv: ['', [Validators.minLength(3)]],
      phoneNumber: ['', [Validators.minLength(10)]],
    });

    this.route.queryParams.subscribe(async (p) => {
      this.merchantId = p['merchantId'];
      this.pump = p['pump'];
    });

    this.pumpService
      .init({
        merchantId: this.merchantId,
        pump: this.pump,
      })
      .subscribe((res: ResCarAtPump) => {
        this.pumpService.log('ResCarAtPump', JSON.stringify(res));
        this.setPlate(res.plate);

        if (res.authorized) {
          this.setState(State.Authorized);
          this.idleReset();
        } else if (
          this.currentState === State.Authorized &&
          res.authorized === false
        ) {
          this.setState(State.Welcome);
          this.idleReset();
        }
      });
  }

  reset() {
    this.pumpService.log('ResetData', this.cardData, this.form.value);
    this.cardData = '';
    this.form.reset();
  }

  setState(state: State) {
    this.pumpService.log(
      'Updating state to ',
      state,
      ' current plate',
      this.plate,
      ' ',
    );

    this.currentState = state;
    const resetDataStats = [State.Welcome, State.Authorized];
    if (resetDataStats.includes(this.currentState)) {
      this.reset();
    }
  }

  @HostListener('document:keydown', ['$event'])
  keyDown(e: KeyboardEvent): void {
    const advanceKeys = ['ArrowRight', 'Enter', 'Tab', 'Go'];
    const backKeys = ['ArrowLeft'];

    if (advanceKeys.includes(e.key) && this.currentState !== State.Authorized) {
      this.advanceState();
    } else if (
      backKeys.includes(e.key) &&
      this.currentState !== State.Welcome
    ) {
      this.setState(this.currentState - 1);
    }
  }

  advanceState() {
    const validators: { [key in State]: validator } = {
      [State.Welcome]: () =>
        this.cardData.length > 16 && this.cardData.includes(';'),
      [State.ZipCode]: () => !this.form.get('zipCode')?.invalid || false,
      [State.Cvv]: () => !this.form.get('cvv')?.invalid || false,
      [State.PhoneNumber]: () =>
        !this.form.get('phoneNumber')?.invalid || false,
      [State.Waiting]: () => true,
      [State.Authorized]: () => true,
    };

    if (validators[this.currentState]()) {
      this.setState(this.currentState + 1);
    }

    // If no plate is recognized, skip collecting phone number and do dummy registration for a one time payment
    if (this.currentState === State.PhoneNumber && this.plate === '') {
      this.setState(State.Waiting);
    }

    if (this.currentState === State.Waiting) {
      this.submit();
    }

    this.idleReset();
    this.form.markAllAsTouched();
  }

  updateValue(val: string) {
    const map: { [key in State]: () => void } = {
      [State.Welcome]: () => {},
      [State.ZipCode]: () => {
        this.form.controls['zipCode'].setValue(val);
        this.form.controls['zipCode'].updateValueAndValidity();
      },
      [State.Cvv]: () => {
        this.form.controls['cvv'].setValue(val);
        this.form.controls['cvv'].updateValueAndValidity();
      },
      [State.PhoneNumber]: () => {
        this.form.controls['phoneNumber'].setValue(val);
        this.form.controls['phoneNumber'].updateValueAndValidity();
      },
      [State.Waiting]: () => {},
      [State.Authorized]: () => {},
    };

    map[this.currentState]();
    this.form.markAllAsTouched();
  }

  emptyPlate() {
    return this.plate === '' || this.plate === noPlate;
  }

  async submit() {
    const plate = this.plate;
    const data = {
      phone: this.form.value.phoneNumber,
      plate: [plate],
      zipCode: this.form.value.zipCode,
      paymentMethods: [
        {
          name: 'creditCard',
          data: {
            track2Data: this.cardData,
            cvv: this.form.value.cvv,
          },
        },
      ],
    };

    this.reset();
    if (this.plate) {
      await axios.post(`${environment.apiUrl}/users`, data);
      await axios.post(
        `${environment.apiUrl}/cars/${this.merchantId}/${this.pump}/${this.plate}`,
      );
    } else {
      const params: AuthorizePumpVisitorDto = {
        user: data,
        merchantId: this.merchantId,
        pump: this.pump,
      };
      const url = `${environment.apiUrl}/cars/authorize-visitor`;
      await axios.post(url, params);
    }
  }

  prettyLicensePlate(plate: string) {
    return plate.toUpperCase().slice(0, 4) + ' ' + plate.toUpperCase().slice(4);
  }

  setPlate(plate: string) {
    this.plate = plate;
  }

  @HostListener('document:keypress', ['$event'])
  captureCard(e: KeyboardEvent): void {
    this.idleReset();
    if (this.currentState === State.Welcome) {
      if (environment.production) {
        this.cardData += e.key;
      } else {
        this.cardData = testCardData;
      }
    }
  }

  resetInterval: any;
  idleReset(): void {
    const resetStates: { [key in State]: number } = {
      [State.Welcome]: -1,
      [State.ZipCode]: 60,
      [State.Cvv]: 60,
      [State.PhoneNumber]: 60,
      [State.Waiting]: 30,
      [State.Authorized]: 6 * 60,
    };
    clearTimeout(this.resetInterval);
    const timeout = resetStates[this.currentState];
    if (timeout < 0) {
      return;
    }

    this.resetInterval = setTimeout(() => {
      this.pumpService.log(
        'ResetInterval',
        this.currentState,
        'plate',
        this.plate,
        'form',
        this.form.value,
      );
      this.reset();
      this.setState(State.Welcome);
    }, timeout * 1000);
  }

  State(): typeof State {
    return State;
  }
}
