<section class="container" [ngClass]="currentState">
  <h1>SIGN UP AND START SAVING!</h1>

  <div class="stepper">
    <div class="line"></div>
    <div class="steps">
      <div class="step" [ngClass]="{ active: currentStep === 1 }">
        <div class="num">1</div>
        <div class="label">Plate</div>
      </div>
      <div class="step" [ngClass]="{ active: currentStep === 2 }">
        <div class="num">2</div>
        <div class="label">Card</div>
      </div>
    </div>
  </div>

  <div
    *ngIf="currentStep === 1"
    class="form state collect-plate-show"
    [formGroup]="registrationFormStepOne"
  >
    <mat-form-field>
      <mat-label>Plate</mat-label>
      <input
        id="plate"
        formControlName="plate"
        required
        matInput
        placeholder="BLL PLT"
        mask="AAAA AAAA"
        minlength="4"
        maxlength="9"
        [validation]="false"
      />
      <mat-error> Invalid license plate. </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Phone Number</mat-label>
      <input
        formControlName="phone"
        autocomplete="tel-national"
        type="tel"
        required
        matInput
        placeholder="123-123-1234"
        mask="(000) 000-0000"
        (keydown)="nextStepInput($event)"
      />
      <mat-error>
        Invalid phone number. Please enter a valid US phone number format (e.g.,
        123-456-7890).
      </mat-error>
    </mat-form-field>

    <div class="inputs-row-buttons">
      <button mat-flat-button (click)="nextStep()">Next</button>
    </div>
  </div>

  <div class="state create-customer-show">
    <div class="progress-holder">
      <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
    </div>
  </div>

  <div class="state tokenize-card-show">
    <p>
      Payment information securely handled by

      <svg
        fill="#ffffff"
        height="16px"
        width="16px"
        version="1.1"
        id="square-logo"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        xml:space="preserve"
      >
        <g id="payment-square">
          <g>
            <path
              d="M17,22H7c-2.8,0-5-2.2-5-5V7c0-2.8,2.2-5,5-5h10c2.8,0,5,2.2,5,5v10C22,19.7,19.8,22,17,22z M7,4C5.3,4,4,5.3,4,7v10
			c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3V7c0-1.7-1.3-3-3-3H7z"
            />
          </g>
          <g>
            <path
              d="M14,9h-4c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1v-4C15,9.4,14.6,9,14,9z"
            />
          </g>
        </g>
      </svg>

      <a target="_blank" href="https://squareup.com/">Square</a>
    </p>
    <div id="credit-card"></div>
    <p class="note">
      By clicking submit you agree to our
      <a target="_blank" href="/tos">Terms of Service</a> and
      <a target="_blank" href="/privacy">Privacy Policy</a>.
    </p>
    <div *ngIf="currentStep === 2">
      <div class="inputs-row-buttons">
        <button mat-flat-button (click)="submit()">Submit</button>
      </div>
    </div>
  </div>

  <div class="state success-show">
    <h2 class="registration-success">
      <mat-icon
        aria-hidden="false"
        aria-label="Done"
        fontIcon="done"
      ></mat-icon>
      <br />
      You are all set!
      <br />
    </h2>
    <p>You should receive an sms to complete your registration.</p>
  </div>
</section>
