import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { States } from './constants';
import { MatSelectModule } from '@angular/material/select';

import axios from 'axios';
import { environment } from 'src/environments/environment';
import { StaxComponent } from 'src/app/payment/stax/stax.component';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    StaxComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  providers: [provideNgxMask()],
})
export class UserRegistrationComponent implements OnInit {
  registrationFormStepOne!: FormGroup;
  registrationFormStepTwo!: FormGroup;
  registrationFormStepThree!: FormGroup;
  currentStep: number = 1;

  statesArray: string[];
  constructor(private formBuilder: FormBuilder) {
    this.statesArray = new States().getStates();
  }

  ngOnInit(): void {
    this.registrationFormStepOne = this.formBuilder.group({
      plate: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      //    email: ['', [Validators.required, Validators.email]]
    });

    this.registrationFormStepTwo = this.formBuilder.group({
      cardNumber: ['', [Validators.required, Validators.pattern('[0-9]{16}')]],
      cardCvv: ['', [Validators.required, Validators.pattern('[0-9]{3}')]],
      cardExp: [
        '',
        [
          Validators.required,
          Validators.pattern('(0?[1-9]|1[0-2])(2[3-9]|[3-9][0-9])'),
        ],
      ],
    });

    this.registrationFormStepThree = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern('.+')]],
      lastName: ['', [Validators.required, Validators.pattern('.+')]],
      addressLine1: ['', Validators.required],
      city: ['', [Validators.required, Validators.pattern('[A-Za-z]+')]],
      state: ['', [Validators.required, Validators.pattern('[A-Za-z]{2}')]],
    });
  }

  nextStep(): void {
    if (this.currentStep === 1 && this.registrationFormStepOne.valid)
      this.currentStep++;
    else if (this.currentStep === 2 && this.registrationFormStepTwo.valid)
      this.currentStep++;
  }

  nextStepInput(e: KeyboardEvent): void {
    if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Go') {
      this.nextStep();
    }
  }

  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  async submit() {
    if (
      !this.registrationFormStepOne.valid ||
      !this.registrationFormStepTwo.valid ||
      !this.registrationFormStepThree.valid
    ) {
    }
    const data = {
      ...this.registrationFormStepOne.value,
      ...this.registrationFormStepThree.value,
      paymentMethods: [
        {
          name: 'creditCard',
          data: { ...this.registrationFormStepTwo.value },
        },
      ],
      plate: [this.registrationFormStepOne.value.plate],
    };

    try {
      await axios.post(`${environment.apiUrl}/users`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error: any) {
      return;
    }
  }
}
