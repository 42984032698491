import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'keypad',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss'],
})
export class KeypadComponent {
  @Input()
  length = 0;
  @Output() newValue = new EventEmitter<string>();
  @Output() done = new EventEmitter<string>();

  value = '';

  click(char: string) {
    if (this.value.length < this.length) {
      this.value += char;
      this.newValue.emit(this.value);
    }
  }

  delete() {
    this.value = this.value.slice(0, -1);
    this.newValue.emit(this.value);
  }

  enter() {
    this.done.emit(this.value);
  }

  enterDisabled() {
    return this.length !== this.value.length;
  }
}
