<div class="search-holder">
  <div class="search-width">
    <mat-form-field>
      <mat-label>Find locations near you</mat-label>
      <input
        id="map-search"
        formControlName="mapSearch"
        matInput
        placeholder="33445"
        minlength="4"
        maxlength="8"
      />
      <button matSuffix mat-icon-button aria-label="Search">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>
  </div>
</div>

<div id="map-locations"></div>
<!-- prettier-ignore -->
