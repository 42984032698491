import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { MatSelectModule } from '@angular/material/select';

import axios from 'axios';
import { environment } from 'src/environments/environment';

declare const Square: any;

@Component({
  selector: 'app-user-registration-square',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatIconModule,
    MatButtonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './user-registration-square.component.html',
  styleUrls: ['./user-registration-square.component.scss'],
})
export class UserRegistrationSquareComponent {
  registrationFormStepOne!: FormGroup;
  customerId = '';
  currentStep = 1;
  squareCard: any;
  creditCardDisplay = 'none';
  loaderDisplay = 'none';
  currentState:
    | 'collect-plate'
    | 'create-customer'
    | 'tokenize-card'
    | 'success'
    | 'failed' = 'collect-plate';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initSquare();
    this.pingBackEnd();
    this.registrationFormStepOne = this.formBuilder.group({
      plate: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      //    email: ['', [Validators.required, Validators.email]]
    });
  }

  async nextStep() {
    if (!this.registrationFormStepOne.valid) {
      this.registrationFormStepOne.markAllAsTouched();
      return;
    }
    this.currentState = 'create-customer';
    if (this.currentStep === 1) {
      this.currentStep++;
    }

    try {
      const customer: any = await axios.post(
        `${environment.apiUrl}/payment/customer`,
        {
          phone: this.registrationFormStepOne.value.phone,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      this.customerId = customer.data.result.customer.id;
      this.currentState = 'tokenize-card';
      this.squareCard.recalculateSize();
    } catch (error: any) {}
  }

  nextStepInput(e: KeyboardEvent): void {
    if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Go') {
      this.nextStep();
    }
  }

  async tokenizeCard() {
    const tokenResult = await this.squareCard.tokenize();
    console.log('Token 1' + tokenResult.token);
    const token2 = await this.squareCard.tokenize();
    console.log('Token 2' + tokenResult.token);
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      console.log(errorMessage);
      throw new Error(errorMessage);
    }
  }

  async submit() {
    if (!this.registrationFormStepOne.valid) {
      return;
    }

    try {
      this.currentState = 'create-customer';
      const token = await this.tokenizeCard();

      const addCard = await axios.post(`${environment.apiUrl}/payment/card`, {
        cardId: token,
        customerId: this.customerId,
      });

      const data = {
        ...this.registrationFormStepOne.value,
        paymentMethods: [
          {
            name: 'square',
            data: {
              customerId: this.customerId,
              cardId: addCard.data.result.card.id,
            },
          },
        ],
        plate: [this.registrationFormStepOne.value.plate],
      };

      await axios.post(`${environment.apiUrl}/users`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error: any) {
      this.currentState = 'tokenize-card';
      return;
    }

    this.currentState = 'success';
  }

  initSquare() {
    const config = environment.square;

    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('type', 'text/javascript');
    scriptElement.setAttribute('src', config.scriptUrl);
    scriptElement.onload = async () => {
      try {
        const payments = Square.payments(config.appId, config.locationId);

        this.squareCard = await payments.card();
        await this.squareCard.attach('#credit-card');
        console.log('square payment created');
      } catch (err) {
        console.error(err);
      }
    };

    const head = document.head.appendChild(scriptElement);
  }

  async pingBackEnd() {
    try {
      await axios.get(`${environment.apiUrl}/health`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {}
  }
}
