<div class="keypad-holder">
  <div class="keypad">
    <div class="row">
      <button (click)="click('1')">1<span>&nbsp; </span></button>
      <button (click)="click('2')">2<span>A B C</span></button>
      <button (click)="click('3')">3<span>D E F</span></button>
    </div>
    <div class="row">
      <button (click)="click('4')">4<span>G H I</span></button>
      <button (click)="click('5')">5<span>J K L</span></button>
      <button (click)="click('6')">6<span>M N O</span></button>
    </div>
    <div class="row">
      <button (click)="click('7')">7<span>P Q R S</span></button>
      <button (click)="click('8')">8<span>T U V</span></button>
      <button (click)="click('9')">9<span>W X Y Z</span></button>
    </div>
    <div class="row">
      <button (click)="delete()"><-</button>
      <button (click)="click('0')">0</button>
      <button (click)="enter()" [disabled]="enterDisabled()">-></button>
    </div>
  </div>
</div>
