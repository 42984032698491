import { Component, OnInit, Inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import axios from 'axios';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatButtonModule],
})
export class ContactComponent implements OnInit {
  baseUrl: string;
  constructor() {
    this.baseUrl = environment.apiUrl;
  }

  ngOnInit(): void {}

  async sendContact(contact: any): Promise<any> {
    let result: any;
    try {
      result = await axios.post(
        `${this.baseUrl}/contact-us`,
        { contact },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (error) {}
  }
}
